export const CreateLead = (data, nameForm, price = 0, additionalInfo = '') => {
	// Карта для переименования ключей
	const keyMapping = {
		'Имя': 'client_name',
		'Телефон': 'client_phone',
	};

	// Поля, которые нужно исключить
	const excludedFields = ['subtitle', 'cf_callback'];

	// Создаем новый объект для данных
	const renamedData = {};
	let messageContent = []; // Хранилище для значений других полей

	// Перебираем исходные данные и переименовываем ключи
	for (const [key, value] of data.entries()) {
		if (excludedFields.includes(key)) {
			// Пропускаем исключенные поля
			continue;
		}
		if (keyMapping[key]) {
			// Переименовываем ключи "Имя" и "Телефон"
			renamedData[keyMapping[key]] = value;
		} else {
			// Все остальные поля собираем в messageContent
			messageContent.push(`${key}: ${value}`);
		}
	}

	// Если есть additionalInfo, добавляем его в конец messageContent
	if (additionalInfo) {
		messageContent.push(additionalInfo);
	}

	// Объединяем messageContent в одну строку с разделением через \n
	renamedData['message'] = messageContent.join('\n');

	// Добавляем дополнительные данные
	renamedData['name_lead'] = nameForm;

	if (price && price !== 0) renamedData['total_price'] = Number(price);

	// Переносим модифицированные данные обратно в FormData
	const updatedFormData = new FormData();
	for (const [key, value] of Object.entries(renamedData)) {
		updatedFormData.append(key, value);
	}

	// Отправляем данные на сервер
	fetch('/amo/amo.php', {
		method: 'POST',
		body: updatedFormData,
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			return response.json(); // Парсим JSON только если ответ успешный
		})
		.then((data) => {
			if (data.status === 'success') {
				console.log('Success:', data)
			} else {
				throw new Error(data.message);
			}
		})
		.catch((error) => console.error('Error:', error));
};
