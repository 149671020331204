import Cookies from 'js-cookie';
import RangeSlider from "./RangeSlider";
import { Offcanvas, Collapse } from 'bootstrap';

const Filter = () => {

	window.bootstrap = {...window.bootstrap, Offcanvas, Collapse};

	const filterAction = document.querySelector('.btn-action-filter');

	if (filterAction) {

		RangeSlider();

		const getDefaultValueFromAttribute = (sliderElement) => {
			const hiddenInput = sliderElement.querySelector('input[type="text"]');
			const defaultValueAttr = hiddenInput.value;
			const values = defaultValueAttr.split(',')
				.map(value => {
					const trimmedValue = value.trim();
					return (trimmedValue.includes('x') || trimmedValue.includes('х') || trimmedValue.includes('мм')) ? trimmedValue : null;
				})
				.filter(value => {
					return value !== '';
				});
		
			return values;
		};
		
		let attributesData = {};
		const rangeSizes = (sliderElement, i) => {
			const rangeSizesArr = sliderElement.querySelectorAll('.multi-range-sizes'),
						idAttribute = sliderElement.getAttribute('data-id-attribute');

			let ranges = {};
			rangeSizesArr.forEach(range => {
				const values = range.noUiSlider.get();
				ranges[range.getAttribute('data-size-name')] = {
						min: Number(values[0]),
						max: Number(values[1])
				}
			});
			
			if (Object.keys(ranges).length > 0) {
				return attributesData[i] = {
					[idAttribute]: ranges
				}
			}
		};

		const rangeSizesDefaultOutput = () => {
			const filterItems = document.querySelectorAll('.catalog-filter-item');
			let result = []; 

			filterItems.forEach((e, i) => {
				rangeSizes(e, i);
				let defaultValues = getDefaultValueFromAttribute(e);
				const idAttribute = e.getAttribute('data-id-attribute');
				
				// Check if the attributesData object has been populated for the current index
				if (attributesData[i] && attributesData[i][idAttribute]) {
						let lengthRange = attributesData[i][idAttribute].length;
						let widthRange = attributesData[i][idAttribute].width;
						let heightRange = attributesData[i][idAttribute].height;
		
						// Filter the defaultValues array to get the values that match the range criteria
						let matchingValues = defaultValues.filter(value => {
								let [length, width, height] = value
										.replace(/мм/g, '')
										.replace(/х/g, 'x')
										.split("_").slice(1).join("_")
										.split('x')
										.map(val => val.trim());
								
								// Check if the value matches the range criteria for length, width, and height (if available)
								let lengthMatch = !lengthRange || (parseInt(length) >= parseInt(lengthRange.min) && parseInt(length) <= parseInt(lengthRange.max));
								let widthMatch = parseInt(width) >= parseInt(widthRange.min) && parseInt(width) <= parseInt(widthRange.max);
								let heightMatch = !heightRange || (parseInt(height) >= parseInt(heightRange.min) && parseInt(height) <= parseInt(heightRange.max));
							

								return lengthMatch && widthMatch && heightMatch;
						});
		
						// Do something with matchingValues
						if (matchingValues.length > 0 && e.getAttribute('data-active') == 'true') {
								result = result.concat(matchingValues);
						} 
				}
			});

			const newResult = [...new Set(result)];
			return newResult.join('%2C');
		};

	
		const urlParams = new URL(window.location).searchParams;
		const url = `https://${window.location.hostname}${window.location.pathname}`;

		filterAction?.addEventListener('click', (event) => {
			event.preventDefault();

			Cookies.remove('filterItemSizes');
			document.querySelectorAll('.catalog-filter-item').forEach((e,i) => rangeSizes(e,i));
			Cookies.set('filterItemSizes', JSON.stringify(attributesData));

			const filtersActive = window.location.search.split('=')[1],
						arrFilterActive = (filtersActive) && Array.from(filtersActive.split('%2C'));
	
			const filterInputs = document.querySelectorAll('.catalog-filter-input:checked');
            const normalizedValues = new Set();
            filterInputs.forEach(input => {
                const normalizedValue = input.value.trim().toLowerCase();
                if (!Array.from(normalizedValues).some(val => val === normalizedValue)) {
                    normalizedValues.add(normalizedValue);
                }
            });
            
            const filterValues = Array.from(normalizedValues);
			const priceSliderValues = document.querySelector('.slider-multi-range-price').noUiSlider.get(),
						minPrice = Number(priceSliderValues[0]),
						maxPrice = Number(priceSliderValues[1]);

			const filterSizes = (rangeSizesDefaultOutput()) ? `&sizes=${rangeSizesDefaultOutput()}` : '',
						mainFilter = (filterValues.length !== 0) ? `&filter=${filterValues.join('%2C')}` : '',
						sort = (Cookies.get('sortItems')) ? `&${Cookies.get('sortItems')}` : '';

			window.location.replace(`${url}?pr=${minPrice}%2C${maxPrice}${filterSizes}${mainFilter}${sort}`);

		});

		document.querySelector('.btn-clear-filter')?.addEventListener('click', () => {
			Cookies.remove('filterItemSizes');
			window.location.replace(url);
		});

		document.querySelectorAll('.catalog-filter-input')?.forEach(e => {
				if (e.checked) {
					e.closest('.accordion-collapse.collapse').classList.add('show');
				}
		});

		document.querySelectorAll('.catalog-filter-item')?.forEach(e => {
			if (e.getAttribute('data-active') == 'true') {
				e.querySelector('.accordion-collapse.collapse').classList.add('show')
			};
		});

		document.querySelector('.catalog-pop-filter-price')?.addEventListener('click', (event) => {
			event.preventDefault();
			const filters = new bootstrap.Offcanvas('#catalog_filter_canvas'),
						canvasBody = document.querySelector('#catalog_filter_canvas .offcanvas-body');

			filters.show();
			canvasBody.scrollTo(0, 0);
		});

		document.querySelector('.catalog-pop-filter-sizes')?.addEventListener('click', (event) => {
			event.preventDefault();
			const filters = new bootstrap.Offcanvas('#catalog_filter_canvas'),
						canvasBody = document.querySelector('#catalog_filter_canvas .offcanvas-body'),
						collapse = new bootstrap.Collapse('#panelsStayOpen-catalog_filter108');

			filters.show();
			canvasBody.scrollTo(0, 100);
			collapse.show();
		});


	}

}

export default Filter;