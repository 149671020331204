import { Modal } from 'bootstrap';
import Noty from 'noty';
import Captcha from '../Services/Captcha';
import { CreateLead } from '../Services/AmoIntegrations';

const BuyToOneClick = () => {
	window.bootstrap = {...window.bootstrap, Modal};
	
	const modal = document.querySelector('#buyToOneClickModal');
	const form = modal?.querySelector('#buyToOneClickForm');

	const resetForm = (form, modal) => {
		modal.hide();
		form.reset();
		form.querySelectorAll('.form__label').forEach(e => {
			e.style.cssText = '';
			e.querySelector('.cf-label-name').style.cssText = '';
		});
	}

	const sendSuccess = (form, modal, successModal) => {
		resetForm(form, modal);
		successModal._element.querySelector('.success-title').textContent = 'Заявка успешно отправлена!';
		successModal.show();
		setTimeout(() => {
			successModal.hide();
		}, 3500);
	}

	const sendContactForm = async (formEvent) => {
		formEvent.preventDefault();
		const form = formEvent.target;
		const successModal = new bootstrap.Modal('#successMessage');
		const dataForm = new FormData(form);
		const currentModal = bootstrap.Modal.getOrCreateInstance(modal);
		const captcha = new Captcha('/catalog/controller/extension/captcha/validate.php', '/catalog/controller/extension/captcha/captcha.php');

		dataForm.append('Товар', form.querySelector('[data-product_name]').getAttribute('data-product_name'));

		form.querySelectorAll('.product-config-value')?.forEach(e => {
			dataForm.append(e.getAttribute('data-option'), e.textContent);
		});
		
		if (form.querySelector('.product-config-item-title')) {
			dataForm.append(form.querySelector('.product-config-item-title').textContent, form.querySelector('.product-config-item-content input').value);
		}

		try {
			await captcha.onAddCaptcha();

			const response = await fetch('index.php?route=mail/mail_send/send', {
				method: 'POST',
				body: dataForm,
				headers: {'X-Requested-With': 'XMLHttpRequest'}
			});
	
			if (response.ok) {
				const data = await response.json();

				if (data.captcha && data.result === 'success') {

					if (window.location.hostname.includes('voronezh')) {
						CreateLead(dataForm, 'Форма купить в 1 клик - frendom.ru', parseInt(document.querySelector('.product-config-item-content.product-price').textContent.replace(' ', '')));
					}

					sendSuccess(form, currentModal, successModal);
				} else {
					throw new Error('Неправильно заполненая captcha или ошибка в форме: ' + response.statusText);
				}

			} else {
				throw new Error('Ошибка на сервере: ' + response.statusText);
			}

		} catch (error) {
			resetForm(form, currentModal);
			new Noty({
				theme: 'relax',
				type: 'error',
				timeout: 3000,
				text: error.message,
			}).show();
			console.error(error);
		}

	}

	form?.addEventListener('submit', event => sendContactForm(event));

}

export default BuyToOneClick;